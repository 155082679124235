import { Button } from '@nextui-org/button';
import { NavbarItem } from '@nextui-org/navbar';
import { cn } from '@nextui-org/theme';
import { NotePencil, Plus } from '@phosphor-icons/react/dist/ssr';
import React from 'react';
import { User as NextAuthUser } from 'next-auth';
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/dropdown';
import Link from 'next/link';

import ProfileDropdown from './ProfileDropdown';
import { HeaderProps } from './Header';

import { body } from '@/theme/typography';

interface AuthNavbarItemProps {
  headerTheme: HeaderProps['variant'];
  user: NextAuthUser;
}

const AuthNavbarItem: React.FC<AuthNavbarItemProps> = ({ headerTheme, user }) => {
  return (
    <NavbarItem className='flex flex-row items-center gap-6'>
      <Dropdown
        showArrow
        classNames={{
          base: 'before:bg-neutral-white',
          content: 'p-0 border-none bg-background rounded-none',
        }}
        offset={20}
        placement='bottom-end'
        radius='sm'
        size='lg'
      >
        <DropdownTrigger>
          <Button
            className={cn('border-gray-50', {
              'text-neutral-white': headerTheme === 'dark',
            })}
            radius='none'
            startContent={<Plus size={20} />}
            variant='bordered'
          >
            Create
          </Button>
        </DropdownTrigger>

        <DropdownMenu aria-label='Profile Menu' className='p-3' variant='light'>
          <DropdownItem
            key='article'
            as={Link}
            className={cn(body())}
            href='/publish/article'
            startContent={<NotePencil size={24} />}
            // showDivider
          >
            Article
          </DropdownItem>

          {/* <DropdownItem
            key='spot'
            className={cn(body())}
            startContent={<ImageSquare size={24} />}
          >
            Gallery spot
          </DropdownItem> */}
        </DropdownMenu>
      </Dropdown>

      {/* <Button
        isIconOnly
        className={cn('relative bg-transparent hover:bg-transparent', {
          'text-neutral-white': headerTheme === 'dark',
        })}
        radius='none'
        variant='light'
      >
        <Badge
          isOneChar
          className='right-[20%] h-3 min-h-3 w-3 min-w-3'
          color='danger'
          isInvisible={false}
          shape='circle'
          size='sm'
          variant='solid'
        >
          <Bell size={24} />
        </Badge>
      </Button> */}

      <ProfileDropdown user={user} />
    </NavbarItem>
  );
};

export default AuthNavbarItem;
